:root {
  /* color */
  --background-black: #2c2c2c;
  --background-main: #f4f3ef;
  --light-black: #66615b;
  --box-black: #444;
  --box-light-grey: rgba(0, 0, 0, 0.12);
  --grid-grey: #ddd;

  /* size */
  --border-radius: 10px;

  /* 전역 스타일 관련 */
  --menu-width: 20vw;
  --menu-width-minus: -20vw;
  --header-height: 8vh;

  /* 다크모드 관련 컬러 */
  --mode-white: #ffffff;
  --mode-95: #eceff1;
  --mode-90: #cfd8dc;
  --mode-80: #b0bec5;
  --mode-70: #90a4ae;
  --mode-30: #455a64;
  --mode-20: #37474f;
  --mode-10: #263238;
  --mode-black: #222222;

  --error-color: #bf0000;
  --interactive-color: #0071bc;

  /* 폰트 사이즈 반응형 rem*/
  --font-10px: 0.625rem;
  --font-12px: 0.75rem;
  --font-14px: 0.875rem;
  --font-16px: 1rem;
  --font-18px: 1.125rem;
  --font-20px: 1.25rem;
  --font-22px: 1.375rem;
  --font-24px: 1.5rem;
  --font-28px: 1.75rem;
}

.bold {
  font-weight: 700;
}
.font20 {
  font-size: 20px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}
